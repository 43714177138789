<template>
<div>
    <form>
        <columns>
            <column>
                <text-input
                    required
                    classes="is-medium is-rounded"
                    :value="widget.title"
                    @input="updateTitle"
                    :error="$root.errors.title"
                    description="The title of the report when printed as a PDF.">
                    Title
                </text-input>
            </column>
            <column class="is-4">
                <select-input
                    required
                    classes="is-medium is-rounded"
                    :items="availableTypes"
                    value-key="value"
                    label-key="label"
                    :value="widget.type"
                    @input="updateType"
                    :error="$root.errors.type"
                    description="The type of report determines which columns and widgets can be used">
                    Type
                </select-input>
            </column>
            <column class="is-narrow">
                <switch-input
                    classes="is-medium is-rounded"
                    :value="widget.show_title"
                    @input="toggleShowTitle"
                    :error="$root.errors.show_title"
                    description="Toggle the title visibility on printed reports">
                    Show Title
                </switch-input>
            </column>
        </columns>
        <columns>
            <column>
                <text-area
                    classes="is-medium is-rounded"
                    :value="widget.intro"
                    @input="updateIntro">
                    <p class="mb-2">Intro/Description</p>

                    <p v-if="activeType" class="has-text-weight-light has-text-grey mb-2">
                        Dynamic values allow you to make your reporting text come alive. 
                    </p>
                    <select-input
                        v-if="activeType"
                        :items="activeType.verbs"
                        v-model="verb"
                        @input="addVerbToDescription"
                        prompt-label="- Add a dynamic value -"
                        classes="mb-2 is-rounded">
                    </select-input>
                </text-area>
            </column>
        </columns>
        <columns v-if="widget.type && activeType.requires_config">
            <column>
                <component :is="`report-widget-builder-${widget.type}`" />
            </column>
        </columns>
        
        <columns>
            <column>
                <submit-button 
                    :working="working"
                    @submit="createWidget"
                    class="is-medium is-rounded">
                    Save
                </submit-button>
            </column>
        </columns>
    </form>
</div>    
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import find from 'lodash/find'
import WidgetTypes from '../../../store/models/reporting/WidgetTypes'

export default {

    data: () => ({
        working: false,
        types: WidgetTypes,
        verb: ''
    }),

    beforeCreate() {
        this.$store.commit('reportWidget/clearReportWidget')
    },

    methods: {
        ...mapMutations('reportWidget', [
            'updateTitle',
            'updateIntro',
            'toggleShowTitle'
        ]),
        updateType(value) {
            this.$store.commit('reportWidget/updateType', value)
            this.updateIntro('')
        },
        createWidget() {
            this.working = true
            this.$store.dispatch('report/createWidget', this.widget).then(() => {
                this.working = false
                this.$toast.success('Saved')
                this.$router.push({
                    name: 'report-widgets',
                    params: {
                        report: this.report.id
                    }
                })
            }).catch(() => this.working = false)
        },
        addVerbToDescription(verb) {
            if(verb) {
                this.updateIntro(`${this.widget.intro}{${verb}} `)
            }
            this.verb = null
        }
    },

    computed: {
        ...mapGetters('reportWidget', [
            'widget',
        ]),
        ...mapGetters('report', [
            'report'
        ]),
        availableTypes() {
            return this.types.filter(type => type.for.includes(this.report.type))
        },
        activeType() {
            return this.widget.type ? find(this.types, type => type.value === this.widget.type) : null
        }
    }

}
</script>